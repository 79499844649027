import React, { Component } from "react";

import Translate from "../components/translation/translate";
import Fade from "react-reveal/Fade";
import OurTeam from "../components/our-team";
import HeroBanner from "../components/hero-banner";
import cooperation from "../../static/cooperation.svg";

import ContactUs from "../components/contact-us";

import Newsletter from "../components/newsletter";
import Page from "../components/page";
import { FiHome } from "react-icons/fi";
import MLS from "../components/mls";
class Home extends Component {
    goToFindYourPlace = () => {
        window.location.href = "/#contact-us";
    };

    render() {
        return (
            <div className="w-100 h-100 position-relative">
                <HeroBanner lang={this.props.language} />
                <div
                    id="summary"
                    className="section container-fluid bg-dark-blue"
                >
                    <div className="container text-center p-4">
                        <Fade distance="5%" bottom>
                            <img
                                src={cooperation}
                                width={120}
                                height={120}
                                alt="cooperation"
                            />
                            <p className="h2 mt-4 text-center text-wrap text-break pb-4 text-white">
                                <Translate
                                    lang={this.props.language}
                                    path={["summary", "parts", 0]}
                                />
                                <span className="text-miami">
                                    <Translate
                                        lang={this.props.language}
                                        path={["summary", "parts", 1]}
                                    />
                                </span>
                                <Translate
                                    lang={this.props.language}
                                    path={["summary", "parts", 2]}
                                />
                            </p>
                            <button
                                className="btn btn-primary ml-auto mr-auto"
                                onClick={this.goToFindYourPlace}
                            >
                                <span>
                                    <FiHome className="mr-2" size={24} />
                                    <Translate
                                        lang={this.props.language}
                                        path={["summary", "button"]}
                                    />
                                </span>
                            </button>
                        </Fade>
                    </div>
                </div>
                <MLS lang={this.props.language} />
                <OurTeam lang={this.props.language} />
                <ContactUs lang={this.props.language} />
                <Newsletter
                    lang={this.props.language}
                    titlePath={["newsletter", "title"]}
                    subtitlePath={["newsletter", "subtitle"]}
                />
            </div>
        );
    }
}

export default () => (
    <Page>
        <Home />
    </Page>
);
