import React, { Component } from "react";
import Fade from "react-reveal/Fade";

import Translate from "./translation/translate";
import {
    FaBed,
    FaBath,
    FaBuilding,
    FaCalendar,
    FaTemperatureHigh,
    FaTemperatureLow,
    FaParking,
    FaStreetView,
    FaSquare,
    FaToilet,
    FaTools,
} from "react-icons/fa";
import { FiDollarSign, FiGrid, FiX } from "react-icons/fi";
import $ from "jquery";
import Property from "./property";
import {
    MdArrowDownward,
    MdArrowUpward,
    MdChevronLeft,
    MdChevronRight,
    MdEmail,
    MdGridOn,
    MdHome,
    MdLocalParking,
    MdPieChart,
    MdRefresh,
    MdSearch,
    MdSort,
    MdStraighten,
    MdToc,
} from "react-icons/md";
import { FaDollarSign, FaHandHoldingUsd } from "react-icons/fa";
import CurrencyInput from "react-currency-input-field";
import PropertyModal from "./property-modal";
import ImageLoader from "./image-loader";
import FastAverageColor from "fast-average-color";

const fac = new FastAverageColor();

class MLS extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        this.overviewData = [
            {
                label: "Type",
                key: "PropertySubType",
                icon: FaBuilding,
            },
            {
                label: "Year built",
                key: "YearBuilt",
                icon: FaCalendar,
            },
            {
                label: "Heating",
                key: "Heating",
                icon: FaTemperatureHigh,
            },
            {
                label: "Cooling",
                key: "Cooling",
                icon: FaTemperatureLow,
            },
            {
                label: "Parking",
                key: "ParkingFeatures",
                icon: FaParking,
            },
            {
                label: "Lot",
                key: "LotSizeSquareFeet",
                icon: MdGridOn,
                isArea: true,
            },
            {
                label: "Price/sqft",
                key: "MIAMIRE_RATIO_CurrentPrice_By_SQFT",
                icon: MdPieChart,
                isCurrency: true,
            },
            {
                label: "Bedrooms",
                key: "BedroomsTotal",
                icon: FaBed,
                separate: true,
            },
            {
                label: "Bathrooms",
                key: "BathroomsTotalDecimal",
                icon: FaBath,
            },
            {
                label: "Full bathrooms",
                key: "BathroomsFull",
                icon: FaToilet,
            },
            {
                label: "Appliances",
                key: "Appliances",
                icon: FaTools,
            },
            {
                label: "Flooring",
                key: "Flooring",
                icon: FiGrid,
            },
        ];

        this.state = {
            query: "",
            averageColor: null,
            listings: [],
            autocompleteData: [],
            activeProperty: null,
            activePropertyImageIndex: 0,
            activePropertyImageUrl: "",
            activePrediction: 0,
            temporaryActivePrediction: 0,
            loading: false,
            activeLocation: false,
            currentPage: 0,
            totalPages: 12,
            sortBy: null,
            listingType: "For rent",
            propertyType: null,
            minPrice: undefined,
            maxPrice: undefined,
            order: "asc",
        };

        this.availablePropertyTypes = [
            "All",
            "Residential",
            "Single Family Residence",
            "Multi Family",
            "Condominium",
            "Townhouse",
        ];

        this.availableSortingMethods = [
            "Relevant",
            "Newest",
            "Lowest price",
            "Highest price",
        ];

        this.availableListingTypes = ["For rent", "For sale"];
    }

    goToNextImage = () => {
        this.setState(prevState => {
            return {
                activePropertyImageIndex:
                    prevState.activePropertyImageIndex + 1,
            };
        });
    };

    goToPrevImage = () => {
        this.setState(prevState => {
            return {
                activePropertyImageIndex:
                    prevState.activePropertyImageIndex - 1,
            };
        });
    };

    setActivePropertyImageIndex = activePropertyImageIndex => {
        this.setState({ activePropertyImageIndex });
    };

    setActiveProperty = (activeProperty, activePropertyImageIndex = 0) => {
        this.setState({ activeProperty }, () => {
            this.setState({ activePropertyImageIndex });
        });
    };

    changeListingType = listingType => {
        this.setState({ listingType });
    };

    resetFilters = () => {
        this.setState({
            sortBy: null,
            propertyType: null,
            minPrice: undefined,
            maxPrice: undefined,
        });
    };

    goToNextPage = () => {
        this.setState(prevState => {
            return {
                currentPage: prevState.currentPage + 1,
            };
        });
    };

    goToPrevPage = () => {
        this.setState(prevState => {
            return {
                currentPage: prevState.currentPage - 1,
            };
        });
    };

    getLocationCoordinates = async placeId => {
        let response = await fetch(
            `${process.env.GATSBY_PLACES_URL}?url=https://maps.googleapis.com/maps/api/place/details/json&key=${process.env.GATSBY_MAPS_API_KEY}&place_id=${placeId}`
        );
        let details = await response.json();
        return details.result.geometry.location;
    };

    getPropertyFilter = () => {
        return this.state.propertyType === null
            ? this.availablePropertyTypes.slice(
                  1,
                  this.availablePropertyTypes.length
              )
            : this.state.propertyType;
    };

    getListings = async () => {
        this.setState({ loading: true });
        let size = 6;
        let url = `https://api.bridgedataoutput.com/api/v2/${
            process.env.GATSBY_BRIDGE_DATASET_ID
        }/listings?access_token=${
            process.env.GATSBY_BRDIGE_BROWSER_TOKEN
        }&limit=${size}&offset=${this.state.currentPage * size}${
            this.state.sortBy ? `&sortBy=${this.state.sortBy}` : ""
        }&order=${this.state.order}&near=${
            this.state.activeLocation
                ? `${this.state.activeLocation.lng},${this.state.activeLocation.lat}`
                : this.state.query
        }&MlsStatus.eq=Active&PropertyType.in=${
            this.state.listingType === "For sale"
                ? "Residential"
                : "Residential Income,Residential Lease"
        }&PropertySubType.in=${this.getPropertyFilter()}&StateOrProvince=FL${
            this.state.minPrice ? `&ListPrice.gt=${this.state.minPrice}` : ""
        }${this.state.maxPrice ? `&ListPrice.lt=${this.state.maxPrice}` : ""}`;
        let response = await fetch(url);
        let listings = [];
        if (response) {
            let data = await response.json();
            console.log(data);
            this.setState({ totalPages: data.total });
            listings = data.success ? data.bundle.slice(0) : [];
        }

        this.setState({ loading: false, autocompleteData: [] });
        return listings;
    };

    updateListings = async () => {
        let autocompletePrediction = this.state.autocompleteData[
            this.state.activePrediction
        ];
        if (autocompletePrediction) {
            this.setState({ query: autocompletePrediction.description });
            await this.updateLocationCoordinates(
                autocompletePrediction.place_id
            );
        }

        this.resetAutocompleteData();
        let listings = await this.getListings();
        this.setState({ listings, temporaryActivePrediction: 0 });
    };

    updateLocationCoordinates = async placeId => {
        let coordinates = await this.getLocationCoordinates(placeId);
        this.setState({ activeLocation: coordinates }, () => {
            return;
        });
    };

    populateAutocomplete = async () => {
        let response = await fetch(
            `${process.env.GATSBY_PREDICTIONS_URL}?url=https://maps.googleapis.com/maps/api/place/autocomplete/json&input=${this.state.query}&key=${process.env.GATSBY_MAPS_API_KEY}`
        );
        let data = await response.json();
        this.setState({ autocompleteData: data.predictions });
    };

    resetAutocompleteData = () => {
        this.setState({ autocompleteData: [] });
    };

    handleQueryChange = () => {
        if (this.state.query.length === 0 || this.state.loading)
            this.resetAutocompleteData();
        if (this.state.temporaryActivePrediction !== null)
            this.populateAutocomplete();
    };

    handleMinPriceChange = minPrice => {
        this.setState({ minPrice });
    };

    handleMaxPriceChange = maxPrice => {
        this.setState({ maxPrice });
    };

    handleOnChange = e => {
        let query = $(e.currentTarget).val();
        this.updateQuery(query);
    };

    clearSearch = () => {
        return this.updateQuery("");
    };

    updateAverageColor = async () => {
        if (this.state.activePropertyImageUrl) {
            if (this.state.activePropertyImageUrl.length) {
                let image = new Image();
                image.src = this.state.activePropertyImageUrl;
                let color = await fac.getColorAsync(image);
                this.setState({ averageColor: color.rgba });
            }
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.query !== this.state.query) this.handleQueryChange();
        if (
            prevState.currentPage !== this.state.currentPage ||
            prevState.sortBy !== this.state.sortBy ||
            prevState.propertyType !== this.state.propertyType ||
            prevState.order !== this.state.order ||
            prevState.listingType !== this.state.listingType
        )
            this.updateListings();

        if (prevState.activePrediction !== this.state.activePrediction) {
            this.setState({
                temporaryActivePrediction: this.state.activePrediction,
            });
        }

        if (
            prevState.activePropertyImageIndex !==
            this.state.activePropertyImageIndex
        ) {
            this.setState({
                activePropertyImageUrl: this.state.activeProperty
                    ? this.state.activeProperty.Media[
                          this.state.activePropertyImageIndex
                      ].MediaURL
                    : null,
            });
        }

        if (prevState.activeProperty !== this.state.activeProperty) {
            this.setState({
                activePropertyImageUrl: this.state.activeProperty
                    ? this.state.activeProperty.Media[0].MediaURL
                    : null,
            });
        }

        if (
            prevState.activePropertyImageUrl !==
            this.state.activePropertyImageUrl
        ) {
            this.updateAverageColor();
        }
    }

    getPredictionLabel = (mainText, matchingStrings) => {
        let charArray = mainText.split("");
        if (!matchingStrings) matchingStrings = [];
        matchingStrings.forEach((stringData, index) => {
            charArray.splice(
                stringData.offset,
                0,
                `<span class="font-weight-bold">`
            );
            charArray.splice(
                stringData.offset + stringData.length,
                0,
                "</span>"
            );
        });
        return (
            <span
                dangerouslySetInnerHTML={{ __html: charArray.join("") }}
            ></span>
        );
    };

    updateQuery = query => {
        return new Promise((resolve, reject) => {
            this.setState({ query, currentPage: 0 }, resolve);
        });
    };

    handleKeyDown = e => {
        if (e.keyCode === 13) {
            if (this.state.autocompleteData)
                if (this.state.autocompleteData.length)
                    this.selectTemporaryPrediction();
                else this.updateListings();
            else this.updateListings();
        }
    };

    goToNextPrediction = () => {
        this.setState(prevState => {
            return {
                temporaryActivePrediction:
                    prevState.temporaryActivePrediction + 1,
            };
        });
    };

    goToPrevPrediction = () => {
        this.setState(prevState => {
            if (prevState.temporaryActivePrediction === 0) return;
            return {
                temporaryActivePrediction:
                    prevState.temporaryActivePrediction - 1,
            };
        });
    };

    handleAutocompleteNavigation = e => {
        const ARROW_KEY_DOWN = 40,
            ARROW_KEY_UP = 38;
        if (e.keyCode === ARROW_KEY_DOWN) {
            this.goToNextPrediction();
        } else if (e.keyCode === ARROW_KEY_UP) {
            this.goToPrevPrediction();
        }
    };

    listenForAutcompleteNavigation = () => {
        $(document).on("keydown", this.handleAutocompleteNavigation);
    };

    selectTemporaryPrediction = () => {
        let index = this.state.temporaryActivePrediction;
        this.selectPrediction(index);
    };

    selectPrediction = index => {
        let prediction = this.state.autocompleteData[index];
        if (prediction) {
            this.setState(
                {
                    activePrediction: index,
                },
                async () => {
                    await this.updateQuery(prediction.description);
                    await this.updateListings();
                }
            );
        }
    };

    delayedResetAutoCompleteData = () => {
        setTimeout(this.resetAutocompleteData, 200);
    };

    changePropertyType = propertyType => {
        if (propertyType === "All") propertyType = null;
        this.setState({ propertyType });
    };

    changeSortByMethod = sortBy => {
        let sortByData = this.getSortByMethod(sortBy);
        let { sortByMethod, order } = sortByData;
        this.setState({ sortBy: sortByMethod, order });
    };

    setOrderAsc = () => {
        this.setState({ order: "asc" });
    };

    setOrderDesc = () => {
        this.setState({ order: "desc" });
    };

    getSortByMethod = sortBy => {
        if (sortBy === "Relevant") sortBy = null;
        let sortByMethod = null,
            order = "asc";

        if (sortBy === "Newest") {
            sortByMethod = "DaysOnMarket";
        } else if (sortBy === "Lowest price") {
            sortByMethod = "ListPrice";
            order = "asc";
        } else if (sortBy === "Highest price") {
            sortByMethod = "ListPrice";
            order = "desc";
        }

        return {
            sortByMethod,
            order,
        };
    };

    componentDidMount() {
        this.updateListings();
        this.listenForAutcompleteNavigation();
    }

    render() {
        let listings = this.state.listings.map(data => (
            <Property
                key={data.url}
                {...data}
                property={data}
                loading={this.state.loading}
                setActiveProperty={this.setActiveProperty}
            />
        ));
        let predictions = this.state.autocompleteData.map(
            (prediction, index) => {
                let matchingSubstrings =
                    prediction.structured_formatting
                        .main_text_matched_substrings;
                let predictionLabel = this.getPredictionLabel(
                    prediction.structured_formatting.main_text,
                    matchingSubstrings
                );
                let active = index === this.state.temporaryActivePrediction;
                return (
                    <div
                        key={prediction.description + index}
                        className={`container-fluid cursor-pointer d-flex align-items-center text-left pt-2 pb-2 ${
                            active ? "selected-prediction" : ""
                        }`}
                        onClick={() => {
                            this.selectPrediction(index);
                        }}
                    >
                        <span className="mr-1">
                            <span>
                                {predictionLabel}{" "}
                                {
                                    prediction.structured_formatting
                                        .secondary_text
                                }
                            </span>
                        </span>
                    </div>
                );
            }
        );
        let realPage = this.state.currentPage + 1;

        let propertyTypes = this.availablePropertyTypes.map(propertyType => {
            let active =
                propertyType === "All"
                    ? this.state.propertyType === null
                    : propertyType === this.state.propertyType;
            return (
                <div
                    className={`dropdown-item cursor-pointer ${
                        active ? "active" : ""
                    }`}
                    onClick={this.changePropertyType.bind(this, propertyType)}
                >
                    {propertyType}
                </div>
            );
        });

        let sortByMethods = this.availableSortingMethods.map(sortByMethod => {
            let sortData = this.getSortByMethod(sortByMethod);
            let active =
                this.state.sortBy === sortData.sortByMethod &&
                this.state.order === sortData.order;
            return (
                <div
                    className={`dropdown-item cursor-pointer ${
                        active ? "active" : ""
                    }`}
                    onClick={this.changeSortByMethod.bind(this, sortByMethod)}
                >
                    {sortByMethod}
                </div>
            );
        });

        let listingTypes = this.availableListingTypes.map(listingType => {
            return (
                <div
                    className="dropdown-item d-flex align-items-center"
                    onClick={this.changeListingType.bind(this, listingType)}
                >
                    <div
                        className={`rounded-circle bg-${
                            listingType === "For rent" ? "primary" : "danger"
                        }`}
                        style={{ width: 12, height: 12 }}
                    ></div>
                    <span className="ml-2">{listingType}</span>
                </div>
            );
        });

        let squareFootage = this.state.activeProperty?.LivingArea
            ? `${Number(
                  this.state.activeProperty?.LivingArea
              ).toLocaleString()} sqft`
            : null;

        let overviewData = this.overviewData.map(info => {
            let Icon = info.icon;
            let propData = this.state.activeProperty
                ? this.state.activeProperty[info.key]
                : "";
            let value = Array.isArray(propData)
                ? propData.join(", ")
                : info.isCurrency
                ? new Intl.NumberFormat("en-us", {
                      style: "currency",
                      currency: "USD",
                  }).format(propData)
                : info.isArea
                ? `${Number(propData).toLocaleString()}`
                : propData;
            return value ? (
                value !== "$0.00" &&
                value !== "0" &&
                value !== 0 &&
                value.length !== 0 ? (
                    <>
                        {info.separate ? <hr></hr> : null}
                        <div className="container-fluid pl-0 pr-0 mt-2 mb-2 d-flex">
                            <span className="font-weight-bold ">
                                <div className="d-flex align-items-center">
                                    <Icon />
                                    <span className="ml-2">{info.label}</span>
                                </div>
                            </span>
                            <div className="ml-2">{value}</div>
                        </div>
                    </>
                ) : null
            ) : null;
        });

        return (
            <>
                <PropertyModal
                    visible={this.state.activeProperty}
                    closeModal={this.setActiveProperty.bind(this, null)}
                >
                    <div className="property-modal-content flex-sm-column flex-md-row">
                        <div className="property-modal-image-viewer col-sm-12 col-md-6 col-lg-8">
                            <div className="property-image-controls pl-4 pr-4">
                                {this.state.activePropertyImageIndex > 0 ? (
                                    <div onClick={this.goToPrevImage}>
                                        <MdChevronLeft />
                                    </div>
                                ) : null}
                                {this.state.activePropertyImageIndex <
                                (this.state.activeProperty
                                    ? this.state.activeProperty.Media.length - 1
                                    : 1) ? (
                                    <div
                                        className="ml-auto"
                                        onClick={this.goToNextImage}
                                    >
                                        <MdChevronRight />
                                    </div>
                                ) : null}
                            </div>
                            <div className="w-100 h-100">
                                <ImageLoader
                                    src={this.state.activePropertyImageUrl}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 property-modal-info pl-4 pr-4">
                            {this.state.activeProperty ? (
                                <>
                                    <div className="container-fluid mt-4 pl-0 pr-0 d-flex align-items-center">
                                        <FaBuilding className="mr-2 text-primary-darker" />
                                        <h3 className="mb-0">Property info</h3>
                                    </div>
                                    <hr></hr>
                                    <p
                                        className="font-weight-bolder text-primary-darker"
                                        style={{ fontSize: "2.5em" }}
                                    >
                                        {new Intl.NumberFormat("en-us", {
                                            style: "currency",
                                            currency: "USD",
                                        }).format(
                                            this.state.activeProperty?.ListPrice
                                        )}
                                    </p>
                                    <div className="d-flex mt-2">
                                        <div className="d-flex align-items-center ">
                                            <FaBed />
                                            <span className="ml-2">
                                                {
                                                    this.state.activeProperty
                                                        ?.BedroomsTotal
                                                }
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center text-dark ml-3">
                                            <FaBath />
                                            <span className="ml-2">
                                                {
                                                    this.state.activeProperty
                                                        ?.BathroomsTotalDecimal
                                                }
                                            </span>
                                        </div>
                                        {squareFootage ? (
                                            <div className="d-flex align-items-center text-dark ml-3">
                                                <MdStraighten />
                                                <span className="ml-2">
                                                    {squareFootage}
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                    <p className="text-muted mt-2">
                                        {this.state.activeProperty?.Location}
                                    </p>
                                    <div className="d-flex flex-wrap mb-4">
                                        {this.state.activeProperty?.AssociationAmenities.map(
                                            amenity => (
                                                <span
                                                    className="badge mt-2 mr-2"
                                                    style={{
                                                        backgroundColor:
                                                            "#EFEFFF",
                                                    }}
                                                >
                                                    {amenity}
                                                </span>
                                            )
                                        )}
                                    </div>
                                    <div>
                                        <div
                                            className="btn btn-primary"
                                            onClick={() => {
                                                window.open(
                                                    `mailto:hello@jamregroup.com?subject=Inquiry for property on ${this.state.activeProperty.Location}`
                                                );
                                            }}
                                        >
                                            <MdEmail />
                                            <span>Contact Agent</span>
                                        </div>
                                    </div>
                                    <div className="mt-4 mb-4">
                                        <p className="font-weight-bolder">
                                            Overview
                                        </p>
                                        <div>{overviewData}</div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                </PropertyModal>
                <div
                    id="listings"
                    className="section container-fluid bg-primary-darker min-vh-100"
                >
                    <div className="container text-center p-4">
                        <p className="display-4 text-underlined text-light center">
                            <Translate
                                lang={this.props.lang}
                                path={["mls", "title"]}
                            />
                        </p>
                        <div
                            className="container d-flex flex-column flex-md-row flex-wrap"
                            style={{ marginTop: "4em" }}
                        >
                            <div className="d-flex align-items-center justify-content-center w-100">
                                <div className="mls-input">
                                    <div className="d-flex align-items-center w-100">
                                        <input
                                            type="text"
                                            value={this.state.query}
                                            placeholder="Address, City, or Neighborhood"
                                            onChange={this.handleOnChange}
                                            onKeyDown={this.handleKeyDown}
                                            onBlur={
                                                this
                                                    .delayedResetAutoCompleteData
                                            }
                                            onFocus={this.populateAutocomplete}
                                            ref={this.inputRef}
                                        />
                                        {this.state.loading ? (
                                            <div className="d-flex align-items-center justify-content-center ml-4">
                                                <div
                                                    className="spinner-border spinner-border-sm"
                                                    style={{ color: "#1760ff" }}
                                                    role="status"
                                                >
                                                    <span className="sr-only">
                                                        Loading...
                                                    </span>
                                                </div>
                                            </div>
                                        ) : this.state.query.length ? (
                                            <div onClick={this.clearSearch}>
                                                <FiX />
                                            </div>
                                        ) : null}
                                    </div>
                                    {this.state.autocompleteData.length ? (
                                        <div className="predictions">
                                            {predictions}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="container-fluid mt-4 mb-4">
                                <div className="container ml-auto mr-auto d-flex flex-column flex-md-row justify-content-center">
                                    <div className="dropdown">
                                        <div
                                            className="btn btn-light"
                                            data-toggle="dropdown"
                                        >
                                            <div
                                                className={`rounded-circle bg-${
                                                    this.state.listingType ===
                                                    "For rent"
                                                        ? "primary"
                                                        : "danger"
                                                }`}
                                                style={{
                                                    width: 12,
                                                    height: 12,
                                                    minWidth: 12,
                                                    minHeght: 12,
                                                }}
                                            ></div>
                                            <span>
                                                {this.state.listingType}
                                            </span>
                                        </div>
                                        <div className="dropdown-menu mt-2">
                                            {listingTypes}
                                        </div>
                                    </div>
                                    <div className="dropdown">
                                        <div
                                            className="btn btn-light mt-2 mt-md-0 ml-sm-0 ml-md-4"
                                            data-toggle="dropdown"
                                        >
                                            <FaDollarSign />
                                            <span>Price</span>
                                        </div>
                                        <div className="dropdown-menu mt-2">
                                            <form
                                                className="container mt-2"
                                                style={{ minWidth: 200 }}
                                            >
                                                <div className="form-group">
                                                    <label className="text-muted small">
                                                        Min Price
                                                    </label>
                                                    <CurrencyInput
                                                        className="form-control shadow-sm"
                                                        prefix="$"
                                                        defaultValue={0}
                                                        value={
                                                            this.state.minPrice
                                                        }
                                                        onValueChange={
                                                            this
                                                                .handleMinPriceChange
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="text-muted small">
                                                        Max Price
                                                    </label>
                                                    <CurrencyInput
                                                        className="form-control shadow-sm"
                                                        prefix="$"
                                                        value={
                                                            this.state.maxPrice
                                                        }
                                                        onValueChange={
                                                            this
                                                                .handleMaxPriceChange
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className="btn btn-primary mb-2"
                                                    onClick={
                                                        this.updateListings
                                                    }
                                                >
                                                    <MdSearch />
                                                    <span>Search</span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="dropdown">
                                        <div
                                            className="btn btn-light mt-2 mt-md-0 ml-sm-0 ml-md-4"
                                            data-toggle="dropdown"
                                        >
                                            <MdHome />
                                            <span>Type</span>
                                        </div>
                                        <div className="dropdown-menu mt-2">
                                            {propertyTypes}
                                        </div>
                                    </div>
                                    <div className="dropdown">
                                        <div
                                            className="btn btn-light mt-2 mt-md-0 ml-sm-0 ml-md-4"
                                            data-toggle="dropdown"
                                        >
                                            <MdSort />
                                            <span>Sort by</span>
                                        </div>
                                        <div className="dropdown-menu mt-2">
                                            {sortByMethods}
                                        </div>
                                    </div>
                                    <div className="mt-2 mt-md-0 ml-sm-0 ml-md-4 d-flex align-items-center justify-content-center">
                                        <MdArrowUpward
                                            color={
                                                this.state.order === "desc"
                                                    ? "#ffee96"
                                                    : "#FFF"
                                            }
                                            onClick={this.setOrderDesc}
                                        />
                                        <MdArrowDownward
                                            className="ml-2"
                                            color={
                                                this.state.order === "asc"
                                                    ? "#ffee96"
                                                    : "#FFF"
                                            }
                                            onClick={this.setOrderAsc}
                                        />
                                    </div>
                                    {this.state.minPrice !== undefined ||
                                    this.state.maxPrice !== undefined ||
                                    this.state.propertyType !== null ||
                                    this.state.sortBy !== null ? (
                                        <Fade distance="5%" bottom>
                                            <div
                                                className="btn btn-light mt-2 mt-md-0 ml-sm-0 ml-md-4"
                                                onClick={this.resetFilters}
                                            >
                                                <MdRefresh />
                                            </div>
                                        </Fade>
                                    ) : null}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-4 w-100 pl-4 pr-4">
                                {this.state.totalPages ? (
                                    <div className="text-white text-uppercase">
                                        {realPage} of {this.state.totalPages}
                                    </div>
                                ) : null}
                                <div className="d-flex align-items-center justify-content-center">
                                    {this.state.currentPage > 0 ? (
                                        <div
                                            className="btn btn-primary"
                                            onClick={this.goToPrevPage}
                                        >
                                            <MdChevronLeft />
                                        </div>
                                    ) : null}
                                    {this.state.currentPage <=
                                    this.state.totalPages ? (
                                        <div
                                            className={`btn btn-primary ${
                                                this.state.currentPage > 0
                                                    ? "ml-4"
                                                    : ""
                                            }`}
                                            onClick={this.goToNextPage}
                                        >
                                            <MdChevronRight />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {this.state.listings.length === 0 ? (
                                <div className="mt-4 mb-4 pt-4 text-center w-100 d-flex flex-column align-items-center fade-in">
                                    <div className="empty-state-img-container">
                                        <div className="empty-state-img"></div>
                                    </div>
                                    <h1 className="text-white mt-4">
                                        {this.state.query.length ? (
                                            <>
                                                No results for{" "}
                                                <span
                                                    style={{ color: "#ffdf80" }}
                                                >
                                                    "{this.state.query}"
                                                </span>
                                                . Try adjusting your search or
                                                filters.
                                            </>
                                        ) : (
                                            <>
                                                Ooops. We couldn't find anything
                                                with those filters.
                                            </>
                                        )}
                                    </h1>
                                </div>
                            ) : null}
                            <div className="d-flex flex-wrap w-100 mt-5">
                                {listings}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default MLS;
