import React, { Component } from "react";

import {
    FiPhoneCall,
    FiFacebook,
    FiInstagram,
    FiTwitter,
} from "react-icons/fi";
import Fade from "react-reveal/Fade";
import bg from "../../static/hero-banner.png";

import Translate from "./translation/translate";

class HeroBanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            social: [
                {
                    name: "Facebook",
                    link: "https://www.facebook.com/jamregroup/",
                    icon: FiFacebook,
                },
                {
                    name: "Instagram",
                    link: "https://www.instagram.com/jamregroup/",
                    icon: FiInstagram,
                },
                {
                    name: "Twitter",
                    link: "https://twitter.com/jamregroup",
                    icon: FiTwitter,
                },
            ],
        };
    }
    render() {
        let social = this.state.social.map((socialMedia, index) => {
            let Icon = socialMedia.icon;
            return (
                <a
                    target="_blank"
                    key={`social-${index}`}
                    className="text-white text-decoration-none ml-2 mr-2 social"
                    href={socialMedia.link}
                >
                    <Icon size={28} />
                </a>
            );
        });
        return (
            <div
                className="container-fluid min-vh-100 d-flex align-items-center justify-content-center flex-column text-center"
                style={{
                    // backgroundColor: '#ffd2c8',
                    backgroundImage: `url(${bg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundAttachment: "fixed",
                    padding: "20em 4em 12em 4em",
                }}
            >
                <Fade bottom distance="5%">
                    <p className="display-1 text-white">
                        <Translate
                            lang={this.props.lang}
                            path={["heroBanner", "title"]}
                        />
                    </p>
                    <p className="display-2 text-white font-fancy text-normal">
                        <Translate
                            lang={this.props.lang}
                            path={["heroBanner", "subtitle"]}
                        />{" "}
                        <span className="text-miami">
                            <Translate
                                lang={this.props.lang}
                                path={["heroBanner", "special"]}
                            />
                            .
                        </span>
                    </p>
                    <a
                        href="tel:(305) 224 6820"
                        className="text-decoration-none"
                    >
                        <button type="button" className="btn btn-primary mt-4">
                            <FiPhoneCall />
                            <span>
                                <Translate
                                    lang={this.props.lang}
                                    path={["heroBanner", "buttonText"]}
                                />
                            </span>
                        </button>
                    </a>

                    <div className="container" style={{ marginTop: "4em" }}>
                        {social}
                    </div>
                </Fade>
            </div>
        );
    }
}

export default HeroBanner;
