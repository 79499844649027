import React, { Component } from "react";
import Fade from "react-reveal/Fade";

import Translate from "./translation/translate";
import TeamMember from "./team-member";

import profileOne from "../../static/profile-1.jpg";
import profileTwo from "../../static/profile-2.jpg";
import profileThree from "../../static/profile-3.jpg";
import profileFour from "../../static/profile-4.jpg";

class OurTeam extends Component {
    constructor(props) {
        super(props);

        this.state = {
            team: [
                {
                    name: "Jimena Murabito",
                    occupation: "Principal Broker",
                    profileImage: profileOne,
                    bio: `Equipped with over 15 years of international property management experience, Jimena Murabito is a licensed real estate professional based in Brooklyn, New York. While Jimena works across the gamut of buyers, sellers, and renters in the city, she specializes in connecting foreign buyers with their dream American vacation and investment properties. Originally from Argentina, Jimena is also bilingual and a citizen of the world, but she has enjoyed calling New York her home these past 10 years. Jimena brings her cultural knowledge, strong creative background and passion for community to the diverse, rapidly evolving NYC market.
                        
                        When not working, Jimena has many varied interests, including; travel, motorcycles, visual arts, and yoga.
                        `,
                    links: [
                        {
                            url: "tel:917.765.8991",
                            type: "phone",
                        },
                        {
                            url: "mailto:jimena@jamregroup.com",
                            type: "email",
                        },
                        {
                            url: "https://chatwith.io/s/5f84bf8f8b27b",
                            type: "whatsapp",
                        },
                    ],
                },
                {
                    name: "Ariel Murabito",
                    occupation: "Licensed Real Estate Salesperson",
                    profileImage: profileTwo,
                    bio:
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. ",
                    links: [
                        {
                            url: "tel:786.709.8780",
                            type: "phone",
                        },
                        {
                            url: "ariel@jamregroup.com",
                            type: "email",
                        },
                        {
                            url: "https://chatwith.io/s/5f84c06337a89",
                            type: "whatsapp",
                        },
                    ],
                },
                {
                    name: "Matias Heinrich",
                    occupation: "Sales Agent",
                    profileImage: profileFour,
                    bio:
                        "A young Argentine entrepreneur from the province of Misiones with a strong background in sales, career in which he was in various fields of commerce, specialising in residential purchasing and selling at Remax, Buenos Aires. In 2020 Matias joined JAM Real Estate Group as regional manager, he’s base in our Nuñez office in Capital Federal.\nMatias graduated as a public auctioneer from the National University of the Northeast. He is quick-witted, thoughtful and knowledgeable, with a passion for providing the best attention and personalized service tailored to the needs of our clients.",
                    links: [
                        {
                            url: "mailto:matias@jamregroup.com",
                            type: "email",
                        },
                        {
                            url: "tel:+5491125820707",
                            type: "whatsapp",
                        },
                    ],
                },
            ],
        };
    }

    render() {
        let team = this.state.team.map((member, index) => (
            <TeamMember
                key={"team-member-" + index}
                {...member}
                lang={this.props.lang}
                index={index}
            />
        ));
        return (
            <div id="our-team" className="section container-fluid bg-light">
                <div className="container text-center p-4">
                    <Fade distance="5%" bottom>
                        <p className="display-4 text-underlined center">
                            <Translate
                                lang={this.props.lang}
                                path={["ourTeam", "title"]}
                            />
                        </p>
                        <div
                            className="container d-flex flex-column flex-md-row flex-wrap"
                            style={{ marginTop: "4em" }}
                        >
                            {team}
                        </div>
                    </Fade>
                </div>
            </div>
        );
    }
}

export default OurTeam;
