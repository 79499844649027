import React, { Component } from "react";
class PropertyModal extends Component {
    constructor(props) {
        super(props);
        this.counter = 0;
        this.state = {
            ready: false,
            hidden: true,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible !== this.props.visible) {
            this.counter = this.counter + 1;
            if (this.counter) {
                this.setState({ ready: true, hidden: false });
            }

            if (!this.props.visible)
                setTimeout(() => {
                    this.setState({ hidden: true });
                }, 450);
            else {
                this.setState({ hidden: false });
            }
        }
    }

    render() {
        return this.state.hidden ? null : (
            <div
                className={`property-modal-container ${
                    this.state.ready
                        ? this.props.visible
                            ? "fade-in"
                            : "fade-out"
                        : "invisible"
                }`}
            >
                <div
                    className="property-modal-bg"
                    onClick={this.props.closeModal}
                ></div>
                <div
                    className={`property-modal ${
                        this.props.visible ? "expand-in" : "expand-out"
                    }`}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default PropertyModal;
