import React, { Component } from "react";

class ImageLoader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    setLoading = loading => {
        this.setState({ loading });
    };

    loadImage = () => {
        let img = new Image();
        img.src = this.props.src;
        this.setLoading(true);
        img.onload = () => {
            this.setLoading(false);
        };
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.src !== this.props.src) {
            this.loadImage();
        }
    }

    componentDidMount() {
        this.loadImage();
    }

    render() {
        let imageStyles = {
            backgroundImage: `url(${this.props.src})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            position: "relative",
        };

        let loadingContainerStyles = {
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
        };

        let style = this.props.style;

        return (
            <div
                className={`d-flex align-items-center justify-content-center ${
                    this.props.className ?? ""
                } w-100 h-100`}
                style={{ ...imageStyles, ...style }}
            >
                {this.props.loading ? (
                    <div style={loadingContainerStyles}>
                        <div
                            className="spinner-border spinner-border-sm"
                            style={{ color: "#000" }}
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default ImageLoader;
