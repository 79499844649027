import React, { Component } from "react";
import { IoLogoWhatsapp, IoMdCall, IoMdMail } from "react-icons/io";
import Translate from "./translation/translate";
class TeamMember extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
        };
    }

    toggleActive = () => {
        this.setState(prevState => {
            return {
                active: !prevState.active,
            };
        });
    };

    render() {
        let links = this.props.links.map((link, index) => {
            let Icon =
                link.type === "email"
                    ? IoMdMail
                    : link.type === "phone"
                    ? IoMdCall
                    : IoLogoWhatsapp;
            return (
                <div key={"link-" + link.type + index} className="ml-3 mr-3">
                    <a
                        target="_blank"
                        rel="noreferrer"
                        className="social"
                        href={link.url}
                    >
                        <Icon size={24} />
                    </a>
                </div>
            );
        });
        return (
            <div className={"col-sm-12 col-md-6 col-lg-4 mt-4"}>
                <div className="container-fluid m-2 bg-white shadow-sm rounded-lg p-4">
                    <div className="container-fluid d-flex align-items-center justify-content-center">
                        <div
                            className="rounded-circle bg-light shadow-sm"
                            style={{
                                width: "140px",
                                height: "140px",
                                minWidth: "140px",
                                backgroundImage: `url(${this.props.profileImage})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "top center",
                            }}
                        ></div>
                    </div>
                    <p className="font-weight-bold mt-4 card-title">
                        {this.props.name}
                    </p>
                    <p className="font-weight-bold text-muted">
                        <Translate
                            lang={this.props.lang}
                            path={[
                                "ourTeam",
                                "teamMembers",
                                this.props.index,
                                "occupation",
                            ]}
                        />
                    </p>
                    <div
                        className={`team-member-bio ${
                            this.state.active ? "expanded" : ""
                        }`}
                    >
                        <p className="text-muted white-space-pre-line">
                            <Translate
                                lang={this.props.lang}
                                path={[
                                    "ourTeam",
                                    "teamMembers",
                                    this.props.index,
                                    "bio",
                                ]}
                            />
                        </p>
                        <div className="team-member-bio-toggle-container">
                            <div
                                className="font-weight-bolder text-uppercase small cursor-pointer"
                                onClick={this.toggleActive}
                            >
                                {this.state.active ? "See less" : "See more"}
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4 mb-2 align-items-center justify-content-center">
                        {links}
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamMember;
