import React, { Component } from "react";
import { FaBed, FaBath, FaExpand } from "react-icons/fa";
import {
    MdStraighten,
    MdDirections,
    MdEmail,
    MdChevronLeft,
    MdChevronRight,
} from "react-icons/md";
import ImageLoader from "./image-loader";

const Fade = require("react-reveal/Fade");
const parser = require("parse-address");

class Property extends Component {
    constructor(props) {
        super(props);

        this.state = {
            parsedLocation: "",
            currentImage: 0,
        };
    }

    goToNextImage = () => {
        this.setState(prevState => {
            return {
                currentImage: prevState.currentImage + 1,
            };
        });
    };

    goToPrevImage = () => {
        this.setState(prevState => {
            return {
                currentImage: prevState.currentImage - 1,
            };
        });
    };

    parseLocation = location => {
        let parsed = parser.parseLocation(location);
        return `${this.props.StreetNumber} ${
            this.props.StreetDirPrefix ?? ""
        } ${this.props.StreetName}${
            this.props.UnitNumber === this.props.StreetNumber
                ? ""
                : this.props.UnitNumber
                ? " #" + this.props.UnitNumber
                : ""
        },\n${this.props.City}, ${parsed.state} ${parsed.zip}`;
    };

    openDirections = () => {
        window.open(
            `https://www.google.com/maps/place/${this.state.parsedLocation}`,
            "_blank"
        );
    };

    emailAgent = () => {
        window.open(
            `mailto:hello@jamregroup.com?subject=Inquiry for property on ${this.state.parsedLocation}`
        );
    };

    convertPropertyType = propertyType => {
        if (
            propertyType === "Single Family Residence" ||
            propertyType === "Multi Family"
        ) {
            return "House";
        } else {
            return propertyType;
        }
    };

    setAsActiveProperty = () => {
        this.props.setActiveProperty(
            { ...this.props.property, Location: this.state.parsedLocation },
            this.state.currentImage
        );
    };

    componentDidMount() {
        let parsedLocation = this.parseLocation(this.props.UnparsedAddress);
        this.setState({ parsedLocation });
    }

    render() {
        let parsedPrice = new Intl.NumberFormat("en-us", {
            style: "currency",
            currency: "USD",
        }).format(this.props.ListPrice);
        let squareFootage = this.props.LivingArea
            ? `${Number(this.props.LivingArea).toLocaleString()} sqft`
            : null;
        let currentImageUrl = this.props.Media
            ? this.props.Media[
                  this.state.currentImage > this.props.Media.length - 1
                      ? this.props.Media.length - 1
                      : this.state.currentImage
              ].MediaURL
            : "";
        return (
            <div className="col-sm-12 col-md-6 col-lg-4 p-3 pt-4 pb-4 fade-in">
                <div
                    className={`w-100 h-100 bg-light overflow-hidden shadow-sm property ${
                        this.props.loading ? "loading" : ""
                    }`}
                    style={{ borderRadius: "0.8rem" }}
                >
                    <div className="property-loading-overlay">
                        <div className="spinner-border"></div>
                    </div>
                    <div className="property-image-container">
                        <div className="property-image-controls">
                            {this.state.currentImage > 0 ? (
                                <div onClick={this.goToPrevImage}>
                                    <MdChevronLeft />
                                </div>
                            ) : null}
                            {this.state.currentImage <
                            (this.props.Media
                                ? this.props.Media.length - 1
                                : 1) ? (
                                <div
                                    className="ml-auto"
                                    onClick={this.goToNextImage}
                                >
                                    <MdChevronRight />
                                </div>
                            ) : null}
                        </div>
                        <Fade
                            bottom
                            duration={600}
                            distance="5%"
                            spy={currentImageUrl}
                        >
                            <>
                                <div
                                    className="position-absolute"
                                    style={{ zIndex: 9999 }}
                                >
                                    <div
                                        className="property-expand-btn btn btn-light btn-sm cursor-pointer"
                                        onClick={this.setAsActiveProperty}
                                    >
                                        <FaExpand className="mt-1 mb-1" />
                                    </div>
                                </div>
                                <ImageLoader
                                    src={currentImageUrl}
                                    className="property-image"
                                />
                            </>
                        </Fade>
                    </div>
                    <div className="p-3 text-left">
                        <div className="text-muted text-uppercase small d-flex align-items-center justify-content-between">
                            {this.convertPropertyType(
                                this.props.PropertySubType
                            )}
                            <span
                                className={`badge ${
                                    this.props.PropertyType === "Residential"
                                        ? "badge-danger"
                                        : "badge-primary"
                                } ml-2`}
                            >
                                {" "}
                                {this.props.PropertyType === "Residential"
                                    ? "for sale"
                                    : "for rent"}
                            </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                            <span
                                className="text-primary-darker"
                                style={{ fontSize: "1.8em", fontWeight: 500 }}
                            >
                                {parsedPrice}
                            </span>
                            <div className="btn btn-secondary btn-sm">
                                <MdDirections
                                    className="cursor-pointer"
                                    style={{ fontSize: "1.4em" }}
                                    onClick={this.openDirections}
                                />
                            </div>
                        </div>
                        <div className="d-flex mt-2">
                            <div className="d-flex align-items-center ">
                                <FaBed />
                                <span className="ml-2">
                                    {this.props.BedroomsTotal}
                                </span>
                            </div>
                            <div className="d-flex align-items-center text-dark ml-3">
                                <FaBath />
                                <span className="ml-2">
                                    {this.props.BathroomsTotalDecimal}
                                </span>
                            </div>
                            {squareFootage ? (
                                <div className="d-flex align-items-center text-dark ml-3">
                                    <MdStraighten />
                                    <span className="ml-2">
                                        {squareFootage}
                                    </span>
                                </div>
                            ) : null}
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-3">
                            <div
                                className="small text-uppercase text-wrap white-space-pre-line"
                                style={{ opacity: 0.71 }}
                            >
                                {this.state.parsedLocation}
                            </div>
                            <div
                                className="btn btn-primary"
                                onClick={this.emailAgent}
                            >
                                <MdEmail />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Property;
