import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import TextareaAutosize from "react-textarea-autosize";
import { FiSend } from "react-icons/fi";
import Translate from "./translation/translate";

const fetch = require("node-fetch");

class ContactUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sendingMessage: false,
            clientInterest: "Buying",
            clientProperty: "House",
            clientTimeFrame: "Just starting to look",
            clientName: "",
            clientEmail: "",
            clientMessage: "",
            availableInterests: ["Buying", "Selling", "Renting", "Inquiry"],
            availablePropertyTypes: ["House", "Condo", "Apartment", "Loft"],
            availableTimeframes: [
                "Just starting to look",
                "0-3 months",
                "3-6 months",
                "6-12 months",
                "When my dream home appears",
            ],
        };
    }

    setSendingMessage = sendingMessage => {
        this.setState({ sendingMessage });
    };

    changeClientInterest = clientInterest => {
        this.setState({ clientInterest });
    };

    changeClientPropertyType = clientProperty => {
        this.setState({ clientProperty });
    };

    changeClientTimeFrame = clientTimeFrame => {
        this.setState({ clientTimeFrame });
    };

    handleClientNameChange = e => {
        let name = e.currentTarget.value;
        this.setState({ clientName: name });
    };

    handleClientEmailChange = e => {
        let email = e.currentTarget.value;
        this.setState({ clientEmail: email });
    };

    handleClientMessageChange = e => {
        let message = e.currentTarget.value;
        this.setState({ clientMessage: message });
    };

    getDataAsMessage = () => {
        console.log(Object.entries(this.state));
        let props = Object.entries(this.state).filter(
            key => key[0].indexOf("client") > -1
        );
        let message = props
            .map(prop => {
                let sanitized = prop[0].replace("client", "");
                let row = `${sanitized}: ${this.state[prop[0]]}`;
                return row;
            })
            .join("</br>");
        return message;
    };

    handleFormSubmit = async () => {
        let message = this.getDataAsMessage();
        this.setSendingMessage(true);
        const params = {
            subject: `New contact lead: ${this.state.clientName}`,
            message,
        };
        let res = await fetch("/contact-list", {
            method: "POST",
            body: encodeURI(
                `subject=${params.subject}&message=${params.message}`
            ),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
        let json = await res.json();
        let name = this.state.clientName.split(" ");
        let campaignResult = await fetch(
            `/campaign-management/add-client?firstName=${name[0]}&lastName=${
                name[1] ? name[1] : ""
            }&email=${this.state.clientEmail}`,
            {
                method: "POST",
            }
        );
        console.log(json);
        this.setSendingMessage(null);
    };

    handleClientInterestChange = e => {
        let value = e.currentTarget.value;
        this.changeClientInterest(value);
    };

    handleClientPropertyChange = e => {
        let value = e.currentTarget.value;
        this.changeClientPropertyType(value);
    };

    handleClientTimeFrameChange = e => {
        let value = e.currentTarget.value;
        this.changeClientTimeFrame(value);
    };

    render() {
        let interests = this.state.availableInterests.map((interest, index) => (
            <option key={"interest-" + interest}>
                {new Translate().getTranslation(this.props.lang, [
                    "findYourSpace",
                    "select",
                    0,
                    index,
                ])}
            </option>
        ));

        let properties = this.state.availablePropertyTypes.map(
            (property, index) => (
                <option key={"property-" + property}>
                    {new Translate().getTranslation(this.props.lang, [
                        "findYourSpace",
                        "select",
                        1,
                        index,
                    ])}
                </option>
            )
        );

        let timeFrames = this.state.availableTimeframes.map(
            (timeFrame, index) => (
                <option key={"time-frame-" + timeFrame}>
                    {new Translate().getTranslation(this.props.lang, [
                        "findYourSpace",
                        "select",
                        2,
                        index,
                    ])}
                </option>
            )
        );

        return (
            <div id="contact-us" className="container-fluid bg-primary-darker">
                <div className="section container text-center">
                    <Fade distance="5%" bottom>
                        <p className="display-4 text-secondary text-underlined center">
                            <Translate
                                lang={this.props.lang}
                                path={["findYourSpace", "title"]}
                            />
                        </p>
                        <p className="text-light">
                            <Translate
                                lang={this.props.lang}
                                path={["findYourSpace", "subtitle"]}
                            />
                        </p>
                        <div className="container d-flex align-items-center justify-content-center mt-4">
                            <div className="col-sm-12 col-md-7 text-left text-white">
                                <form>
                                    <Fade distance="5%" bottom>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>
                                                        <Translate
                                                            lang={
                                                                this.props.lang
                                                            }
                                                            path={[
                                                                "findYourSpace",
                                                                "form",
                                                                0,
                                                            ]}
                                                        />
                                                    </label>
                                                    <input
                                                        id="client-name"
                                                        className="form-control"
                                                        type="text"
                                                        value={
                                                            this.state
                                                                .clientName
                                                        }
                                                        onChange={
                                                            this
                                                                .handleClientNameChange
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>
                                                        <Translate
                                                            lang={
                                                                this.props.lang
                                                            }
                                                            path={[
                                                                "findYourSpace",
                                                                "form",
                                                                1,
                                                            ]}
                                                        />
                                                    </label>
                                                    <input
                                                        id="client-email"
                                                        className="form-control"
                                                        type="email"
                                                        value={
                                                            this.state
                                                                .clientEmail
                                                        }
                                                        onChange={
                                                            this
                                                                .handleClientEmailChange
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                <Translate
                                                    lang={this.props.lang}
                                                    path={[
                                                        "findYourSpace",
                                                        "form",
                                                        2,
                                                    ]}
                                                />
                                            </label>
                                            <select
                                                className="custom-select"
                                                value={
                                                    this.state.clientInterest
                                                }
                                                onChange={
                                                    this
                                                        .handleClientInterestChange
                                                }
                                            >
                                                {interests}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                <Translate
                                                    lang={this.props.lang}
                                                    path={[
                                                        "findYourSpace",
                                                        "form",
                                                        3,
                                                    ]}
                                                />
                                            </label>
                                            <select
                                                className="custom-select"
                                                value={
                                                    this.state.clientProperty
                                                }
                                                onChange={
                                                    this
                                                        .handleClientPropertyChange
                                                }
                                            >
                                                {properties}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                <Translate
                                                    lang={this.props.lang}
                                                    path={[
                                                        "findYourSpace",
                                                        "form",
                                                        4,
                                                    ]}
                                                />
                                            </label>
                                            <select
                                                className="custom-select"
                                                value={
                                                    this.state.clientTimeFrame
                                                }
                                                onChange={
                                                    this
                                                        .handleClientTimeFrameChange
                                                }
                                            >
                                                {timeFrames}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                <Translate
                                                    lang={this.props.lang}
                                                    path={[
                                                        "findYourSpace",
                                                        "form",
                                                        5,
                                                    ]}
                                                />
                                            </label>
                                            <TextareaAutosize
                                                className="form-control"
                                                minRows={4}
                                                value={this.state.clientMessage}
                                                onChange={
                                                    this
                                                        .handleClientMessageChange
                                                }
                                            />
                                        </div>
                                    </Fade>
                                </form>
                                <button
                                    type="button"
                                    className="btn btn-light ml-auto mr-auto mt-4"
                                    onClick={this.handleFormSubmit}
                                >
                                    <FiSend />
                                    <span>
                                        {!this.state.sendingMessage ? (
                                            <Translate
                                                lang={this.props.lang}
                                                path={[
                                                    "findYourSpace",
                                                    "form",
                                                    6,
                                                ]}
                                            />
                                        ) : (
                                            <Translate
                                                lang={this.props.lang}
                                                path={[
                                                    "findYourSpace",
                                                    "form",
                                                    7,
                                                ]}
                                            />
                                        )}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        );
    }
}

export default ContactUs;
